import "antd/dist/antd.min.css";
import axios from "axios";
import React, { useState, useEffect } from "react";

import {
  EditOutlined,
  DeleteOutlined,
  UserAddOutlined,
  SaveOutlined,
  SearchOutlined,
  UserOutlined
} from "@ant-design/icons";
import { createGlobalStyle } from "styled-components";

import {
  Row,
  Col,
  Form,
  Layout,
  PageHeader,
  Divider,
  BackTop,
  Table,
  Typography,
  Button,
  Modal,
  Input,
  Select,
  Space
} from "antd";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import swal from "sweetalert";

const { Content } = Layout;
const { Text } = Typography;
const { Option } = Select;

const StyledForm = createGlobalStyle`.ant-form-item {
  margin-bottom: 5px;
}
`;

const layout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 }
};

const Research_staff = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [dataSource1, _dataSource1] = useState([]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [xEmp, _xEmp] = useState("");
  const [xPosition, _xPosition] = useState("");
  const [xProject_Position, _xProject_Position] = useState("");
  const [xCenter, _xCenter] = useState("");

  const [xEmpV, _xEmpV] = useState("");
  const [xPositionV, _xPositionV] = useState("");
  const [xProject_PositionV, _xProject_PositionV] = useState("");
  const [xCenterV, _xCenterV] = useState("");

  const [xresearch_id, _xresearch_id] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    //console.log(token);
    axios
      .post(
        xURL + "authen",
        {},
        {
          headers: {
            Authorization: `Basic ${token}`
          }
        }
      )
      .then(result => {
        if (result.data.status === "ok") {
          //alert("authen sucess");

          //ค้นหา emp
          axios
            .get(xURL + "research_emp")
            .then(result => {
              //console.log(result.data);

              _xEmp(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา position
          axios
            .get(xURL + "research_position")
            .then(result => {
              //console.log(result.data);

              _xPosition(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา project_position
          axios
            .get(xURL + "research_project_position")
            .then(result => {
              //console.log(result.data);

              _xProject_Position(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          //ค้นหา center
          axios
            .get(xURL + "center")
            .then(result => {
              //console.log(result.data);

              _xCenter(result.data);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });

          GetTable();
        } else {
          //alert("authen failed");
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  }, []);

  const GetTable = () => {
    axios
      .get(xURL + "find_Allresearch")
      .then(result => {
        //console.log(result.data);
        Show_research(result.data);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Show_research = zdata => {
    const Tb1 = [];

    let xengname = "",
      xemp = "";

    zdata.forEach((i, idx) => {
      let xengname = i.engname + " " + i.englname,
        xemp = i.empname,
        xposition = i.positionname,
        xproject_position = i.project_positionname,
        xcenter = i.center_name,
        xemail = i.email;

      if (!i.engname) {
        xengname = "";
      }
      if (!i.empname) {
        xemp = "";
      }
      if (!i.positionname) {
        xposition = "";
      }
      if (!i.project_positionname) {
        xproject_position = "";
      }
      if (!i.center_name) {
        xcenter = "";
      }
      if (!i.email) {
        xemail = "";
      }

      Tb1.push({
        key: idx,
        num: idx + 1,
        pre: i.pre,
        name_th: i.name + " " + i.lname,
        name_eng: xengname,
        emp: xemp,
        position: xposition,
        project_position: xproject_position,
        center: xcenter,
        email: xemail,
        phone: i.tel,
        line: i.line_noti,
        research_id: i.id
      });
    });

    _dataSource1(Tb1);
  };

  const bEditclick = zdata => {
    form1.setFieldsValue({
      edtitle_name: zdata.pre,
      edname_th: zdata.name_th,
      edname_eng: zdata.name_eng,
      slemp: zdata.emp,
      slposition: zdata.position,
      slproject_position: zdata.project_position,
      slcenter: zdata.center,
      edemail: zdata.email,
      edphone: zdata.phone,
      edline: zdata.line
    });

    _xresearch_id(zdata.research_id);

    setIsModalVisible(true);
  };

  const SaveEditdata = () => {
    const f = form1.getFieldValue();

    if (!f.edtitle_name) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ ชื่อนำ!", "warning");
    } else if (!f.edname_th) {
      swal("แก้ไข ไม่ได้!", "ยังไม่ได้คีย์ ชื่อนักวิจัย ภาษาไทย!", "warning");
    } else {
      swal({
        title: "ยืนยัน แก้ไขข้อมูลส่วนตัว",
        text: ":-3",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          //console.log(props.research_id)
          updatedata();

          swal({
            title: "แจ้งเตือน!",
            text: "บันทึก สำเร็จ",
            icon: "success",
            buttons: false,
            timer: 2000
          }).then(() => {
            _xEmpV("");
            _xPositionV("");
            _xProject_PositionV("");
            _xCenterV("");

            GetTable();

            setIsModalVisible(false);
          });
        }
      });
    }
  };

  const updatedata = () => {
    const f = form1.getFieldValue();

    //console.log(xEmpV, xPositionV, xProject_PositionV, xCenterV);
    let xnameth = f.edname_th.split(" "),
      xname_eng = f.edname_eng.split(" ");

    axios
      .post(xURL + "update", {
        research_id: xresearch_id,
        title_name: f.edtitle_name,
        first_name_th: xnameth[0],
        last_name_th: xnameth[1],
        first_name_eng: xname_eng[0],
        last_name_eng: xname_eng[1],
        emp: xEmpV,
        position: xPositionV,
        project_position: xProject_PositionV,
        center: xCenterV,
        email: f.edemail,
        phone: f.edphone,
        line: f.edline,
        xcheck: 17
      })
      .then(() => {})
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bDeleteclick = zdata => {
    swal({
      title: "ยืนยัน ลบนักวิจัย",
      text: zdata.name_th,
      icon: "warning",
      dangerMode: true,
      buttons: ["ยกเลิก", "ยืนยัน"]
    }).then(willDelete => {
      if (willDelete) {
        axios
          .post(xURL + "delete", {
            name_th: zdata.name_th,
            xcheck: 23
          })
          .then(result => {})
          .catch(function(error) {
            // handle error
            console.log("problem here", error);
          });

        swal("แจ้งเตือน!", "ลบนักวิจัยสำเร็จ!", "success").then(() => {
          GetTable();
        });
      }
    });
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "num",
      key: "num",
      align: "center",
      width: 80,
      sorter: (a, b) => a.num - b.num,
      render: (text, record, index) => {
        return index + 1;
      }
    },
    {
      title: "ชื่อนำ",
      dataIndex: "pre",
      key: "pre",
      align: "center",
      sorter: (a, b) => {
        return a.pre.localeCompare(b.pre);
      }
    },
    {
      title: "ชื่อนักวิจัย (ภาษาไทย)",
      dataIndex: "name_th",
      key: "name_th",
      sorter: (a, b) => a.num - b.num,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              allowClear
              placeholder="ค้นหาชื่อนักวิจัย (ภาษาไทย)"
              value={selectedKeys[0]}
              onChange={e => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ marginBottom: 5 }}
            >
              {}
            </Input>

            <Space>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  confirm();
                }}
              >
                ค้นหา
              </Button>
              <Button
                type="danger"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  clearFilters();
                }}
              >
                เคลียร์
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name_th.toLowerCase().includes(value.toLowerCase());
      }
    },
    {
      title: "ชื่อนักวิจัย (ภาษาอังกฤษ)",
      dataIndex: "name_eng",
      key: "name_eng",
      sorter: (a, b) => a.num - b.num,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        return (
          <div style={{ padding: 8 }}>
            <Input
              autoFocus
              allowClear
              placeholder="ค้นหาชื่อนักวิจัย (ภาษาอังกฤษ)"
              value={selectedKeys[0]}
              onChange={e => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                confirm({ closeDropdown: false });
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ marginBottom: 5 }}
            >
              {}
            </Input>

            <Space>
              <Button
                icon={<SearchOutlined />}
                type="primary"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  confirm();
                }}
              >
                ค้นหา
              </Button>
              <Button
                type="danger"
                size="small"
                style={{ width: 90 }}
                onClick={() => {
                  clearFilters();
                }}
              >
                เคลียร์
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) => {
        return record.name_eng.toLowerCase().includes(value.toLowerCase());
      }
    },
    {
      title: "ระดับ",
      dataIndex: "emp",
      key: "emp",
      align: "center",
      width: 170,
      sorter: (a, b) => {
        return a.emp.localeCompare(b.emp);
      }
    },
    {
      title: "ตำแหน่ง",
      dataIndex: "position",
      key: "position",
      align: "center",
      width: 140,
      sorter: (a, b) => {
        return a.position.localeCompare(b.position);
      }
    },
    {
      title: "ตำแหน่งนักวิจัย",
      dataIndex: "project_position",
      key: "project_position",
      align: "center",
      sorter: (a, b) => {
        return a.project_position.localeCompare(b.project_position);
      }
    },
    {
      title: "หน่วยงาน",
      dataIndex: "center",
      key: "center",
      align: "center",
      sorter: (a, b) => {
        return a.center.localeCompare(b.center);
      },
      render: (text, record) => __TBShowText(record.center, 0, 1)
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
      align: "center",
      sorter: (a, b) => {
        return a.email.localeCompare(b.email);
      },
      render: (text, record) => __TBShowText(record.email, 0, 1)
    },
    {
      title: "เบอร์ติดต่อ",
      dataIndex: "phone",
      key: "phone",
      align: "center",
      width: 140,
      sorter: (a, b) => a.phone - b.phone
    },
    {
      title: "แก้ไข",
      dataIndex: "update",
      key: "update",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              icon={<EditOutlined />}
              size="small"
              onClick={e => {
                bEditclick(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    },
    {
      title: "ลบ",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      width: 80,
      render(text, record, index) {
        return {
          children: (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={e => {
                bDeleteclick(record);
              }}
            />
          )
        };
      },
      fixed: "right"
    }
  ];

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function handleChange(value) {
    _xEmpV(value);
  }

  function handleChange2(value) {
    _xPositionV(value);
  }

  function handleChange3(value) {
    _xProject_PositionV(value);
  }

  function handleChange4(value) {
    _xCenterV(value);
  }

  //formatttable (Rigth or Left)
  function __TBShowText(zData, zRigth, zLeft) {
    if (zRigth === 1 && zLeft === 0) {
      return (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    } else if (zRigth === 0 && zLeft === 1) {
      return (
        <div style={{ display: "flex" }}>
          <Text>
            {zData}
          </Text>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <Row justify="center">
          <Col xs={24} sm={24} md={24} lg={24} xl={23}>
            <PageHeader
              className="site-page-header"
              title="นักวิจัย / บุคลากร"
            />
            <Divider />

            <Space>
              <Button
                ghost
                type="primary"
                icon={<UserAddOutlined />}
                size="large"
                shape="round"
                onClick={() => {
                  window.open("/Research_input_researcher", "_self");
                }}
              >
                เพิ่ม นักวิจัย / บุคลากร
              </Button>

              <Button
                // ghost
                // type="text"
                icon={<UserOutlined />}
                size="large"
                shape="round"
                onClick={() => {
                  window.open("/Research_rihes_th", "_self");
                }}
              >
                ข้อมูลนักวิจัย แยกตามศุนย์วิจัย
              </Button>
            </Space>

            <br />
            <br />
            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={false}
            />
          </Col>
        </Row>

        <br />
        <br />
        <br />
      </Content>

      <Footer_last />

      <Modal
        title={
          <Text style={{ fontWeight: "bold" }}>แก้ไข นักวิจัย/บุคลากร</Text>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1500}
      >
        <Form form={form1} initialValues={{}} {...layout}>
          <Form.Item label="ชื่อนำ" name="edtitle_name">
            <Input allowClear style={{ width: "50%", color: "black" }} />
          </Form.Item>

          <Form.Item label="ชื่อนักวิจัย (ภาษาไทย)" name="edname_th">
            <Input allowClear style={{ width: "50%", color: "black" }} />
          </Form.Item>

          <Form.Item label="ชื่อนักวิจัย (ภาษาอังกฤษ)" name="edname_eng">
            <Input allowClear style={{ width: "50%", color: "black" }} />
          </Form.Item>

          <Form.Item label="ระดับ" name="slemp">
            <Select onChange={handleChange} style={{ width: "50%" }}>
              {xEmp.length > 0
                ? xEmp.map((i, idx) => {
                    return (
                      <Option value={i.id}>
                        {i.name}
                      </Option>
                    );
                  })
                : <Option value="">
                    {}
                  </Option>}
            </Select>
          </Form.Item>

          <Form.Item label="ตำแหน่ง" name="slposition">
            <Select onChange={handleChange2} style={{ width: "50%" }}>
              {xPosition.length > 0
                ? xPosition.map((i, idx) => {
                    return (
                      <Option value={i.id}>
                        {i.name}
                      </Option>
                    );
                  })
                : <Option value="">
                    {}
                  </Option>}
            </Select>
          </Form.Item>

          <Form.Item label="ตำแหน่งนักวิจัย" name="slproject_position">
            <Select onChange={handleChange3} style={{ width: "50%" }}>
              {xProject_Position.length > 0
                ? xProject_Position.map((i, idx) => {
                    return (
                      <Option value={i.id}>
                        {i.name}
                      </Option>
                    );
                  })
                : <Option value="">
                    {}
                  </Option>}
            </Select>
          </Form.Item>

          <Form.Item label="หน่วยงาน" name="slcenter">
            <Select onChange={handleChange4} style={{ width: "50%" }}>
              {xCenter.length > 0
                ? xCenter.map((i, idx) => {
                    return (
                      <Option value={i.id}>
                        {i.name}
                      </Option>
                    );
                  })
                : <Option value="">
                    {}
                  </Option>}
            </Select>
          </Form.Item>

          <Form.Item label="E-mail" name="edemail">
            <Input allowClear style={{ width: "50%" }} />
          </Form.Item>

          <Form.Item label="เบอร์ติดต่อ" name="edphone">
            <Input allowClear style={{ width: "50%" }} />
          </Form.Item>

          <Form.Item label="Line-Token" name="edline">
            <Input allowClear style={{ width: "50%" }} />
          </Form.Item>
        </Form>
        <Divider />
        <Row justify="center">
          <Space>
            <Button
              type="primary"
              onClick={() => {
                SaveEditdata();
              }}
            >
              <SaveOutlined />บันทึกข้อมูล
            </Button>

            <Button
              onClick={() => {
                window.open("/Research_Pic?rid="+xresearch_id, "_self");
              }}
            >
              <EditOutlined />แก้ไขรูปภาพ
            </Button>
          </Space>
        </Row>
        <br /> <br />
      </Modal>
      <BackTop />
    </React.Fragment>
  );
};
export default Research_staff;
