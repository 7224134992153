import React, { useState, useEffect } from "react";
import axios from "axios";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import {
  Layout,
  Row,
  Col,
  Space,
  Card,
  Divider,
  PageHeader,
  List,
  Table,
  Typography
} from "antd";

import { createGlobalStyle } from "styled-components";

import ThIcon from "../pics/th_icon.png";
import EnIcon from "../pics/en_icon.png";

const { Content } = Layout;
const { Text } = Typography;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_rihes_detail_th = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xLng, _xLng] = useState();
  const [xRid, _xRid] = useState();

  const [dataSource, _dataSource] = useState([]);
  const [dataSource1, _dataSource1] = useState([]);
  const [dataSource2, _dataSource2] = useState([]);
  const [dataSource3, _dataSource3] = useState([]);

  const [xTopic, _xTopic] = useState([]);
  const [xScopusNum, _xScopusNum] = useState();

  const [pageTable, _pageTable] = useState(1);
  const [pageSizeTable, _pageSizeTable] = useState(10);

  useEffect(() => {
    var url = new URL(window.location.href);

    if (url.searchParams.get("pages") == "IDSU") {
      _xLng("IDSU");
    } else if (url.searchParams.get("pages") == "CMBI") {
      _xLng("CMBI");
    } else if (url.searchParams.get("pages") == "NINE") {
      _xLng("NINE");
    } else if (url.searchParams.get("pages") == "SHSR") {
      _xLng("SHSR");
    }

    _xRid(url.searchParams.get("rid"));
    Getdata(url.searchParams.get("rid"));
    Getscopus(url.searchParams.get("rid"));
    Getpatent(url.searchParams.get("rid"));
    Getaward(url.searchParams.get("rid"));
  }, []);

  const Getdata = zdata => {
    axios
      .post(xURL + "getResearch_rihes_detail_th", {
        rid: zdata
      })
      .then(result => {
        const Tb1 = [];

        let xresearcher_expertise = "",
          xresearch_topic_thai = "",
          xpositionex = "";

        if (result.data[0].research_topic_thai) {
          if (result.data[0].research_topic_thai.split("|").length > 0) {
            xresearch_topic_thai = result.data[0].research_topic_thai.split(
              "|"
            );
          } else {
            xresearch_topic_thai = result.data[0].research_topic_thai;
          }

          _xTopic(xresearch_topic_thai);
        } else {
          _xTopic(["-"]);
        }

        result.data.forEach((i, idx) => {
          if (!i.researcher_expertise) {
            xresearcher_expertise = ["-"];
          } else {
            if (i.researcher_expertise.split("|").length > 0) {
              xresearcher_expertise = i.researcher_expertise.split("|");
            } else {
              xresearcher_expertise = i.researcher_expertise;
            }
          }

          if (!i.positionex) {
            xpositionex = ["-"];
          } else {
            if (i.positionex.split("|").length > 0) {
              xpositionex = i.positionex.split("|");
            } else {
              xpositionex = i.positionex;
            }
          }

          Tb1.push({
            key: idx,
            id: i.id,
            name: i.name,
            position: i.position,
            position_aca: i.position_aca,
            positionex: xpositionex,
            email: i.email,
            researcher_expertise: xresearcher_expertise,
            picname: i.picname,
            research_topic_thai: i.research_topic_thai,
            ORCID: i.ORCID,
            scopus_id: i.scopus_id,
            h_index_link: i.h_index_link
          });
        });

        _dataSource(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Getscopus = zdata => {
    axios
      .post(xURL + "getResearch_scopus", {
        rid: zdata
      })
      .then(result => {
        const Tb1 = [];
        let sumscopus = 0;

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            no: idx + 1,
            title: i.title,
            year: i.year,
            link: i.link
          });

          sumscopus = sumscopus + 1;
        });

        _dataSource1(Tb1);
        _xScopusNum(sumscopus);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Getpatent = zdata => {
    axios
      .post(xURL + "getResearch_patent", {
        rid: zdata,
        lang: "th"
      })
      .then(result => {
        const Tb1 = [];
        let xtype = "";

        result.data.forEach((i, idx) => {
          if (i.patent_type == 1) {
            xtype = "อนุสิทธิบัตร";
          } else if (i.patent_type == 2) {
            xtype = "สิทธิบัตร";
          }

          Tb1.push({
            key: idx,
            no: idx + 1,
            patent_name: i.patent_name,
            patent_type: xtype
          });
        });

        _dataSource2(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const Getaward = zdata => {
    axios
      .post(xURL + "getResearch_award", {
        rid: zdata,
        lang: "th"
      })
      .then(result => {
        const Tb1 = [];

        result.data.forEach((i, idx) => {
          Tb1.push({
            key: idx,
            no: idx + 1,
            award: i.award_description_thai,
            year: i.award_year,
            link: i.award_news_thai
          });
        });

        _dataSource3(Tb1);
        // console.log(Tb1);
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const columns1 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 100
    },
    {
      title: "ชื่อเรื่อง",
      dataIndex: "title",
      key: "title",
      width: 800,
      render(text, record, index) {
        return {
          children: (
            <a
              onClick={e => {
                //console.log("corresponding email is :", record.cmumis_id);
                window.open(record.link, "_blank");
              }}
            >
              {text}
            </a>
          )
        };
      }
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 200
    }
  ];

  const columns2 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 100
    },
    {
      title: "ชื่อที่แสดงถึงการประดิษฐ์/การออกแบบผลิตภัณฑ์",
      dataIndex: "patent_name",
      key: "patent_name",
      width: 800
    },
    {
      title: "ประเภท",
      dataIndex: "patent_type",
      key: "patent_type",
      align: "center",
      width: 200
    }
  ];

  const columns3 = [
    {
      title: "ที่",
      dataIndex: "no",
      key: "no",
      align: "center",
      width: 100
    },
    {
      title: "รางวัล",
      dataIndex: "award",
      key: "award",
      width: 800,
      render(text, record, index) {
        if (record.link) {
          return {
            children: (
              <a
                onClick={e => {
                  //console.log("corresponding email is :", record.cmumis_id);
                  window.open(record.link, "_blank");
                }}
              >
                {text}
              </a>
            )
          };
        } else {
          return {
            children: text
          };
        }
      }
    },
    {
      title: "ปี",
      dataIndex: "year",
      key: "year",
      align: "center",
      width: 200
    }
  ];

  const pageOnChange = (zpage, zpageSize) => {
    _pageTable(zpage);
    _pageSizeTable(zpageSize);
  };

  return (
    <React.Fragment>
      <StyledForm />

      {/* <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      /> */}

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
        </style>

        <div
          style={{ float: "right", marginRight: "50px", marginTop: "-40px" }}
        >
          <Space>
            <p
              style={{
                fontSize: "16px",
                marginBottom: "5px",
                fontFamily: "Bai Jamjuree",
                fontWeight: "600"
              }}
            >
              ภาษา :
            </p>
            <div className="img-lang">
              <img
                src={ThIcon}
                alt="th_icon"
                width="40"
                height="40"
                onClick={() => {
                  window.open(
                    "/Research_rihes_detail_th?pages=" + xLng + "&rid=" + xRid,
                    "_self"
                  );
                }}
              />
            </div>

            <div className="img-lang">
              <img
                className="img-lang"
                src={EnIcon}
                alt="en_icon"
                width="40"
                height="40"
                style={{ opacity: "0.3" }}
                onClick={() => {
                  window.open(
                    "/Research_rihes_detail_en?pages=" + xLng + "&rid=" + xRid,
                    "_self"
                  );
                }}
              />
            </div>
          </Space>
        </div>

        <div style={{ height: "10px" }} />
        <Divider />

        <div style={{ margin: "0 20px" }}>
          <List
            size="large"
            itemLayout="vertical"
            bordered
            dataSource={dataSource}
            renderItem={item =>
              <List.Item key={item.name}>
                <Row>
                  <Col>
                    <div>
                      <img
                        width={170}
                        height={235}
                        alt="profile"
                        src={`${xURL}file_research/${item.picname}`}
                      />
                    </div>
                  </Col>

                  <Col>
                    <div
                      style={{
                        marginLeft: "10px",
                        fontSize: "16px",
                        fontFamily: "Bai Jamjuree"
                      }}
                    >
                      <b>ชื่อ-สกุล</b> : {item.name} <br />
                      <b>ตำแหน่งปฏิบัติงาน</b> : {item.position} <br />
                      <b>ตำแหน่งทางวิชาการ</b> : {item.position_aca} <br />
                      {item.positionex[0] !== "-"
                        ? <div>
                            <b>ตำแหน่งบริหาร</b> :{" "}
                            {item.positionex.map((item, index) =>
                              <span>
                                {item}
                                <br />
                              </span>
                            )}
                          </div>
                        : <div />}
                      <b>อีเมล์</b> : {item.email} <br />
                      <b>ความเชี่ยวชาญ</b> :{" "}
                      {item.researcher_expertise.map(
                        (item, index) =>
                          // <span>
                          //   {item}
                          //   <br />
                          // </span>
                          index > 0
                            ? <span>
                                <ul style={{ listStyleType: "square" }}>
                                  <li>
                                    {item}
                                  </li>
                                </ul>
                              </span>
                            : <div />
                      )}
                      <br />
                      <br />
                      <b>ORCID</b> : {item.ORCID}
                      <br />
                      <b>Scopus Author ID</b> : {item.scopus_id}
                      <br />
                      <a
                        style={{
                          marginLeft: "-17px",
                          color: "blue",
                          fontWeight: "bold",
                          fontStyle: "italic"
                        }}
                        onClick={() => {
                          window.open(item.h_index_link, "_blank");
                        }}
                      >
                        Scopus Profile (h-index)
                      </a>
                    </div>
                  </Col>
                </Row>
              </List.Item>}
          />
        </div>

        <br />

        <div style={{ margin: "0 20px" }}>
          <Card
            style={{
              //margin: "10px",
              borderRadius: "15px",
              overflow: "hidden"
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontFamily: "Bai Jamjuree",
                fontWeight: "bold"
              }}
            >
              หัวข้องานวิจัยในปัจจุบัน / งานวิจัยที่สนใจ
            </p>
            <p
              style={{
                fontSize: "16px",
                fontFamily: "Bai Jamjuree"
              }}
            >
              {xTopic.map(
                (item, index) =>
                  item !== ""
                    ? <span >
                        <ul style={{ listStyleType: "square" }}>
                          <li style={{marginBottom:'-12px'}}>
                            {item}
                          </li>
                        </ul>
                      </span>
                    : <br />
                // <span>
                //   {item}
                //   <br />
                // </span>
              )}
            </p>
            <br />

            <p
              style={{
                fontSize: "16px",
                fontFamily: "Bai Jamjuree",
                fontWeight: "bold"
              }}
            >
              ผลงานตีพิมพ์ใน Scopus จำนวน <b>{xScopusNum}</b> เรื่อง
            </p>

            <Table
              bordered
              scroll={{ x: "max-content" }}
              dataSource={dataSource1}
              columns={columns1}
              size="small"
              pagination={{
                onChange: (zpage, zpageSize) => {
                  pageOnChange(zpage, zpageSize);
                },
                current: pageTable,
                pageSize: pageSizeTable
              }}
            />
            <br />

            {dataSource2.length > 0
              ? <div>
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Bai Jamjuree",
                      fontWeight: "bold"
                    }}
                  >
                    ทรัพย์สินทางปัญญา
                  </p>

                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource2}
                    columns={columns2}
                    size="small"
                    pagination={false}
                  />
                  <br />
                </div>
              : <div />}

            {dataSource3.length > 0
              ? <div>
                  <p
                    style={{
                      fontSize: "16px",
                      fontFamily: "Bai Jamjuree",
                      fontWeight: "bold"
                    }}
                  >
                    รางวัล
                  </p>

                  <Table
                    bordered
                    scroll={{ x: "max-content" }}
                    dataSource={dataSource3}
                    columns={columns3}
                    size="small"
                    pagination={false}
                  />
                </div>
              : <div />}
          </Card>
        </div>
      </Content>

      <div style={{ height: "100px" }} />
      {/* <div style={{ height: "200px" }} />

      <Footer_last /> */}
    </React.Fragment>
  );
};

export default Research_rihes_detail_th;
