import React, { useState, useEffect } from "react";
import axios from "axios";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import {
  Layout,
  PageHeader,
  Divider,
  Row,
  Col,
  Card,
  Form,
  Input,
  Space,
  Button
} from "antd";

import { createGlobalStyle } from "styled-components";

import { ClearOutlined, SaveOutlined } from "@ant-design/icons";

import swal from "sweetalert";

const { Content } = Layout;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Add_Edit_Pic = () => {
  const [form1] = Form.useForm();

  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const [xTitle, _xTitle] = useState();
  const [xType, _xType] = useState();
  const [xRid, _xRid] = useState();
  const [xPage, _xPage] = useState();
  const [xRname, _xRname] = useState();
  const [xPicname, _xPicname] = useState();

  const [ListFile, _ListFile] = useState([]);

  useEffect(() => {
    var url = new URL(window.location.href);

    // if (url.searchParams.get("type") == "Add") {
    //   _xTitle("เพิ่ม รูปภาพนักวิจัย");
    // } else if (url.searchParams.get("type") == "Edit") {
    //   _xTitle("แก้ไข รูปภาพนักวิจัย");
    // }

    // _xTitle("เพิ่ม/แก้ไข รูปภาพนักวิจัย");

    // _xType(url.searchParams.get("type"));
    _xRid(url.searchParams.get("rid"));
    // _xPage(url.searchParams.get("pages"));

    Getdata(url.searchParams.get("rid"));
  }, []);

  const Getdata = zdata => {
    axios
      .post(xURL + "getResearch_rihes_detail_th", {
        rid: zdata
      })
      .then(result => {
        _xRname(result.data[0].name);
        _xPicname(result.data[0].picname);

        if (result.data[0].picname == null || result.data[0].picname == "") {
          _xTitle("เพิ่ม รูปภาพนักวิจัย");
        } else {
          _xTitle("แก้ไข รูปภาพนักวิจัย");
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  const bClearclick = () => {
    form1.resetFields();
    _ListFile([]);
  };

  const bSaveclick = () => {
    if (ListFile == "" || typeof ListFile == "undefined") {
      swal("บันทึก ไม่ได้!", "ยังไม่ได้เลือก ไฟล์รูป!", "warning");
    } else {
      swal({
        title: "ยืนยันบันทึก ไฟล์รูป",
        text: ":3",
        icon: "warning",
        buttons: ["ยกเลิก", "ยืนยัน"]
      }).then(willOk => {
        if (willOk) {
          insertPic();
        }
      });
    }
  };

  const insertPic = () => {
    let ztype = "";
    // if (xType == "Add") {
    //   ztype = "Add";
    // } else if (xType == "Edit") {
    //   ztype = "Edit";
    // }

    if (xPicname == null || xPicname == "") {
      ztype = "Add";
    } else {
      ztype = "Edit";
    }

    const fd = new FormData();
    fd.append("file", ListFile);

    axios.post(xURL + "uploadPic_Research", fd).then(res => {
      axios
        .post(xURL + "uploadPic_insert", {
          rid: xRid,
          f_name: res.data.filename,
          type: ztype
        })
        .then(result => {
          // window.open("Research_rihes_list_th?pages=" + xPage, "_self");
          window.open("Research_staff", "_self");
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    });
  };

  const onChangeU = e => {
    //console.log(e, e.target.files[0]);

    _ListFile(e.target.files[0]);
  };

  return (
    <React.Fragment>
      <StyledForm />

      <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      />

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
        </style>

        <PageHeader className="site-page-header" title={xTitle} />
        <Divider />
        <div style={{ margin: "0 50px" }}>
          <Card
            style={{
              borderRadius: "15px",
              overflow: "hidden"
            }}
          >
            <Row>
              <p
                style={{
                  fontSize: "16px",
                  fontFamily: "Bai Jamjuree",
                  fontWeight: "bold"
                }}
              >
                {xRname}
              </p>
            </Row>
            {xPicname !== ""
              ? <Row>
                  <img
                    width={250}
                    height={325}
                    alt="profile"
                    src={`${xURL}file_research/${xPicname}`}
                  />
                </Row>
              : <div />}

            <br />
            <Row>
              <Form form={form1}>
                <Form.Item
                  label="Picture File"
                  name="edfile"
                  rules={[
                    {
                      required: true
                    }
                  ]}
                >
                  <Input
                    className="form-control"
                    id="drop-area"
                    type="file"
                    accept="image/*"
                    onChange={e => {
                      onChangeU(e);
                    }}
                  />
                </Form.Item>
              </Form>
            </Row>

            <br />

            <Row>
              <Space>
                <Button ghost type="primary" onClick={bSaveclick}>
                  <SaveOutlined /> บันทึก
                </Button>

                <Button ghost type="danger" onClick={bClearclick}>
                  <ClearOutlined /> เคลียร์
                </Button>
              </Space>
            </Row>
          </Card>
        </div>
      </Content>

      <div style={{ height: "200px" }} />

      <Footer_last />
    </React.Fragment>
  );
};

export default Add_Edit_Pic;
