import React, { useState, useEffect } from "react";
import axios from "axios";

import Header_title from "../controls/Header_title";
import Footer_last from "../controls/Footer_last";

import { createGlobalStyle } from "styled-components";

import { Layout, Row, Col, Space, Card } from "antd";

import "./research_rihes.css";

import Cardpic1 from "../pics/card1.jpg";
import Cardpic2 from "../pics/card2.jpg";
import Cardpic3 from "../pics/card3.jpg";
import Cardpic4 from "../pics/card4.jpg";
import ThIcon from "../pics/th_icon.png";
import EnIcon from "../pics/en_icon.png";

const { Content } = Layout;

const StyledForm = createGlobalStyle`.ant-form-item {
    margin-bottom: 5px;
  }
  `;

const Research_rihes_th = () => {
  let xURL = "";
  let xlocation = window.location.hostname;

  if (xlocation === "localhost") {
    xURL = "http://localhost:3002/api/";
  } else {
    xURL = "https://research.rihes.cmu.ac.th/api/";
  }

  const fontstyle = {
    textAlign: "center",
    fontSize: "18px",
    marginBottom: "5px",
    fontFamily: "Bai Jamjuree",
    fontWeight: "600",
    color: "white",
    textShadow: "2px 2px #FF0000"
  };

  return (
    <React.Fragment>
      <StyledForm />

      {/* <Header_title
        tname={localStorage.getItem("tname")}
        research_id={localStorage.getItem("research_id")}
      /> */}

      <Content
        className="site-layout"
        style={{ padding: "0 20px", marginTop: 64 }}
      >
        <style>
          @import
          url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
        </style>

        <div
          style={{ float: "right", marginRight: "50px", marginTop: "-40px" }}
        >
          <Space>
            <p
              style={{
                fontSize: "16px",
                marginBottom: "5px",
                fontFamily: "Bai Jamjuree",
                fontWeight: "600"
              }}
            >
              ภาษา :
            </p>
            <div className="img-lang">
              <img
                src={ThIcon}
                alt="th_icon"
                width="40"
                height="40"
                onClick={() => {
                  window.open("/Research_rihes_th", "_self");
                }}
              />
            </div>

            <div className="img-lang">
              <img
                className="img-lang"
                src={EnIcon}
                alt="en_icon"
                width="40"
                height="40"
                style={{ opacity: "0.3" }}
                onClick={() => {
                  window.open("/Research_rihes_en", "_self");
                }}
              />
            </div>
          </Space>
        </div>

        <div className="center">
          <Row justify="center">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Card
                hoverable
                style={{
                  margin: "10px 10px",
                  height: "110px",
                  backgroundImage: `url(${Cardpic1})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: 0.9
                }}
                onClick={() => {
                  window.open("/Research_rihes_list_th?pages=IDSU", "_blank");
                }}
              >
                <p style={fontstyle}>ศูนย์วิจัยโรคติดเชื้อและสารเสพติด</p>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Card
                hoverable
                style={{
                  margin: "10px 10px",
                  height: "110px",
                  backgroundImage: `url(${Cardpic2})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: 0.9
                }}
                onClick={() => {
                  window.open("/Research_rihes_list_th?pages=CMBI", "_blank");
                }}
              >
                <p style={fontstyle}>ศูนย์วิจัยชีวโมเลกุลและเซลล์วิทยา</p>
              </Card>
            </Col>
          </Row>
          <Row justify="center">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Card
                hoverable
                style={{
                  margin: "10px 10px",
                  height: "110px",
                  backgroundImage: `url(${Cardpic3})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: 0.9
                }}
                onClick={() => {
                  window.open("/Research_rihes_list_th?pages=NINE", "_blank");
                }}
              >
                <p style={fontstyle}>
                  ศูนย์วิจัยโรคไม่ติดเชื้อและอนามัยสิ่งแวดล้อม
                </p>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Card
                hoverable
                style={{
                  margin: "10px 10px",
                  height: "110px",
                  backgroundImage: `url(${Cardpic4})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  opacity: 0.9
                }}
                onClick={() => {
                  window.open("/Research_rihes_list_th?pages=SHSR", "_blank");
                }}
              >
                <p style={fontstyle}>สำนักวิชาการวิจัยวิทยาศาสตร์สุขภาพ</p>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>

      {/* <div style={{ height: "750px" }} />

      <Footer_last /> */}
    </React.Fragment>
  );
};

export default Research_rihes_th;
